import React from 'react';
import {Input} from '@chakra-ui/react';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';

export const DateTimeInput = (props: ReactDatePickerProps) => (
    <DatePicker
        customInput={<Input />}
        locale="de"
        showTimeSelect
        enableTabLoop={false}
        timeFormat="HH:mm"
        dateFormat="dd.MM.yyyy HH:mm"
        timeCaption="Uhrzeit"
        minDate={new Date()}
        timeIntervals={10}
        {...props}
    />
);
