/* eslint-disable react/require-default-props */
import React from 'react';
import {
    Checkbox,
    CheckboxGroup,
    CheckboxGroupProps,
    FormLabel,
    Stack,
    StackProps,
    Popover,
} from '@chakra-ui/react';
import {FilterPopoverButton, FilterPopoverContent} from './filter-popover';
import {useFilterState} from './use-filter-state';

export type CheckboxFilterProps = Omit<CheckboxGroupProps, 'onChange'> & {
    hideLabel?: boolean;
    options: Array<{label: string; value: string}>;
    label?: string;
    onChange?: (value: string[]) => void;
    spacing?: StackProps['spacing'];
};

export const CheckboxFilter = ({
    options,
    label,
    hideLabel,
    spacing = '2',
    ...rest
}: CheckboxFilterProps) => (
    <Stack as="fieldset" spacing={spacing}>
        {!hideLabel && (
            <FormLabel fontWeight="semibold" as="legend" mb="0">
                {label}
            </FormLabel>
        )}

        <CheckboxGroup {...rest}>
            {options.map((option) => (
                <Checkbox
                    key={option.value}
                    value={option.value}
                    colorScheme="brand"
                >
                    {option.label}
                </Checkbox>
            ))}
        </CheckboxGroup>
    </Stack>
);

export const CheckboxFilterPopover = ({
    label = '',
    options = [],
    onSubmit = console.log,
}: {
    label?: string;
    options: Array<{label: string; value: string}>;
    onSubmit: (value: string[]) => void;
}) => {
    const state = useFilterState({
        defaultValue: [],
        onSubmit,
    });
    return (
        <Popover placement="bottom-start">
            <FilterPopoverButton label={label} />
            <FilterPopoverContent
                isCancelDisabled={!state.canCancel}
                onClickApply={state.onSubmit}
                onClickCancel={state.onReset}
            >
                <CheckboxFilter
                    hideLabel
                    value={state.value}
                    onChange={(v) => state.onChange(v)}
                    options={options}
                />
            </FilterPopoverContent>
        </Popover>
    );
};
