import {
    Wrap,
    WrapItem,
    Text,
    Box,
    ButtonGroup,
    Button,
    Alert,
    AlertIcon,
    List,
    ListItem,
    AlertTitle,
    AlertDescription,
    Stack,
} from '@chakra-ui/react';
import {DateTimeInput} from './date-time-input';
import isWeekend from 'date-fns/isWeekend';
import isSaturday from 'date-fns/isSaturday';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import isToday from 'date-fns/isToday';
import nextMonday from 'date-fns/nextMonday';
import addDays from 'date-fns/addDays';
import isValid from 'date-fns/isValid';
import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import isPast from 'date-fns/isPast';
import isBefore from 'date-fns/isBefore';
import {Base_Category_Enum} from '@/generated/graphql.types';
import isSameDay from 'date-fns/isSameDay';
import isAfter from 'date-fns/isAfter';
import setSeconds from 'date-fns/setSeconds';

const minTime = (date: Date) => setSeconds(setHours(setMinutes(date, 0), 7), 0);
const maxTime = (date: Date) =>
    !isWeekend(date)
        ? setSeconds(setHours(setMinutes(date, 50), 16), 0)
        : setSeconds(setHours(setMinutes(date, 30), 7), 0);

const getNextDayOrNextMondayIfToday = (date: Date) => {
    if (isToday(date)) {
        return isWeekend(addDays(date, 1))
            ? nextMonday(date)
            : addDays(date, 1);
    }

    return date;
};

const validateDateRange = (from: Date, to: Date): string[] => {
    const errors = [];
    if (!isValid(from)) {
        errors.push('Die Abholzeit ist ungültig.');
    }
    if (!isValid(to)) {
        errors.push('Die Rückgabezeit ist ungültig.');
    }
    if (isPast(from)) {
        errors.push('Die Abholzeit darf nicht in der Vergangenheit liegen.');
    }
    if (!isBefore(from, to)) {
        errors.push('Die Abholzeit muss vor der Rückgabezeit liegen.');
    }

    const min = minTime(from);
    const max = maxTime(from);

    if (isBefore(from, min) || isAfter(from, max)) {
        errors.push('Die Abholzeit liegt ausserhalb der Öffnungszeiten.');
    }

    return errors;
};

type FromToBannerProps = {
    initialFrom: Date;
    initialTo: Date;
    onFromChange: (from: Date) => void;
    onToChange: (to: Date) => void;
    activeBaseCategory?: Base_Category_Enum;
    children?: ReactNode;
    onValidationChanged: (isValid: boolean) => void;
};

export const FromToBanner = ({
    initialFrom,
    initialTo,
    onFromChange,
    onToChange,
    activeBaseCategory,
    children,
    onValidationChanged,
}: FromToBannerProps) => {
    const [from, setFrom] = useState(initialFrom);
    const [to, setTo] = useState(initialTo);

    const validationErrors = useMemo(
        () => validateDateRange(from, to),
        [from, to]
    );

    useEffect(() => {
        onFromChange(from);
    }, [from, onFromChange]);

    useEffect(() => {
        onToChange(to);
    }, [onToChange, to]);

    useEffect(() => {
        onValidationChanged(validationErrors.length === 0);
    }, [validationErrors, onValidationChanged]);

    const handleFromChange = useCallback(
        (value: Date) => {
            if (!isSameDay(value, from)) {
                const newFrom = setHours(setMinutes(value, 0), 7);
                setFrom(newFrom);
                setTo(addDays(newFrom, 1));
            } else {
                setFrom(value);
                setTo(addDays(value, 1));
            }
        },
        [from]
    );

    const handleToChange = useCallback((value: Date) => {
        setTo(value);
    }, []);

    const handleMorningClick = useCallback(() => {
        const day = getNextDayOrNextMondayIfToday(from);
        setFrom(setHours(setMinutes(day, 0), 7));
        setTo(setHours(setMinutes(day, 0), 12));
    }, [from]);

    const handleAfternoonClick = useCallback(() => {
        const day = getNextDayOrNextMondayIfToday(from);
        setFrom(setHours(setMinutes(day, 0), 12));
        setTo(setHours(setMinutes(day, 0), 17));
    }, [from]);

    return (
        <Stack spacing={4}>
            <Wrap spacing={3}>
                <WrapItem>
                    <Box>
                        <Text>Abholzeit</Text>
                        <DateTimeInput
                            selected={from}
                            minTime={minTime(from)}
                            maxTime={maxTime(from)}
                            onChange={handleFromChange}
                        />
                    </Box>
                </WrapItem>
                <WrapItem>
                    <Box>
                        <Text>Rückgabezeit</Text>
                        <DateTimeInput
                            selected={to}
                            onChange={handleToChange}
                        />
                    </Box>
                </WrapItem>
                {children}
            </Wrap>
            {activeBaseCategory === Base_Category_Enum.Transporter &&
                !isWeekend(from) && (
                    <Box>
                        <Text>Halbtagesmiete</Text>
                        <ButtonGroup>
                            <Button onClick={handleMorningClick}>
                                Vormittag
                            </Button>
                            <Button onClick={handleAfternoonClick}>
                                Nachmittag
                            </Button>
                        </ButtonGroup>
                    </Box>
                )}

            {validationErrors && validationErrors.length > 0 && (
                <Alert status="error" variant="left-accent">
                    <AlertIcon />
                    <List>
                        {validationErrors.map((e) => (
                            <ListItem key={e}>{e}</ListItem>
                        ))}
                    </List>
                </Alert>
            )}

            {isSaturday(from) && (
                <Alert status="info" variant="left-accent">
                    <AlertIcon />
                    <Box>
                        <AlertTitle>
                            Bitte holen Sie Ihr Fahrzeug am Freitag zwischen
                            15:45 und 16:45 Uhr bei uns ab.
                        </AlertTitle>
                        <AlertDescription>
                            Es entstehen Ihnen dadurch keine zusätzlichen
                            Kosten!
                        </AlertDescription>
                    </Box>
                </Alert>
            )}
            {activeBaseCategory === Base_Category_Enum.Transporter &&
                isWeekend(from) && (
                    <Alert status="info" variant="left-accent">
                        <AlertIcon />
                        Wir weisen darauf hin, dass Halbtagestarife nur von
                        Montag bis Freitag angeboten werden.
                    </Alert>
                )}
        </Stack>
    );
};
