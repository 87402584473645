import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {
    ButtonGroup,
    Button,
    Box,
    Text,
    Stack,
    Select,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import {Link} from '@chakra-ui/next-js';
import {useNhostClient} from '@nhost/nextjs';
import {FaCar, FaTruck} from 'react-icons/fa';
import addDays from 'date-fns/addDays';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import startOfDay from 'date-fns/startOfDay';
import isDateValid from 'date-fns/isValid';
import isPast from 'date-fns/isPast';
import {GetServerSideProps} from 'next';
import Layout from '../components/layout';
import {CheckboxFilterPopover} from '../components/checkbox-filter';
import SectionDivider from '../components/section-divider';
import {CalculatingVehicleTypeCard} from '../components/calculating-vehicle-type-card';
import {VehicleTypeGrid} from '../components/vehicle-type-grid';
import {createApolloClient} from '../lib/admin-apollo-client';
import {generateHref} from '../lib/generate-href';
import {
    GetProductsForBaseCategoryDocument,
    GetFilterDataDocument,
    VehicleTypesDocument,
} from '../generated/graphql.hooks';
import {
    GetProductsForBaseCategoryQuery,
    GetProductsForBaseCategoryQueryVariables,
    GetFilterDataQuery,
    GetFilterDataQueryVariables,
    Base_Category_Enum,
    VehicleTypesQuery,
    VehicleTypesQueryVariables,
    Fuel_Enum,
    Gearshift_Enum,
} from '../generated/graphql.types';
import {DiscountType} from '../lib/types';
import {fuelToText, gearshiftToText} from '@/lib/enum-helpers';
import setSeconds from 'date-fns/setSeconds';
import setMilliseconds from 'date-fns/setMilliseconds';
import {FromToBanner} from '@/components/from-to-banner';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import {toDate, utcToZonedTime} from 'date-fns-tz';

export type IndexPageProps = {
    allowModeChange?: boolean;
    allowDiscountChange?: boolean;
    initialDiscountId?: string;
    initialInsuranceId: string;
    activeBaseCategory?: Base_Category_Enum;
    discounts?: {
        id: string;
        name: string;
        value: number;
        is_default?: boolean;
    }[];
    vehicleCategories?: {
        id: string;
        name: string;
    }[];
    gearshiftTypes?: Gearshift_Enum[];
    fuelTypes?: Fuel_Enum[];
    vehicleTypes: VehicleTypesQuery['vehicle_type'];
};

const IndexPage = ({
    allowModeChange = true,
    allowDiscountChange = true,
    initialDiscountId = '',
    initialInsuranceId,
    activeBaseCategory = Base_Category_Enum.Car,
    discounts = [],
    vehicleCategories = [],
    gearshiftTypes = [],
    fuelTypes = [],
    vehicleTypes = [],
}: IndexPageProps) => {
    const router = useRouter();
    const nhost = useNhostClient();

    let initialFrom = useMemo(
        () =>
            zonedTimeToUtc(
                setHours(
                    setMinutes(
                        setSeconds(
                            setMilliseconds(addDays(new Date(), 3), 0),
                            0
                        ),
                        0
                    ),
                    7
                ),
                'Europe/Vienna'
            ),
        []
    );
    0;
    let initialTo = useMemo(() => addDays(initialFrom, 1), [initialFrom]);
    if (
        router.query.from &&
        router.query.to &&
        !Array.isArray(router.query.from) &&
        !Array.isArray(router.query.to)
    ) {
        const parsedFrom = utcToZonedTime(
            toDate(router.query.from),
            'Europe/Vienna'
        );
        const parsedTo = utcToZonedTime(
            toDate(router.query.to),
            'Europe/Vienna'
        );

        if (isDateValid(parsedFrom) && !isPast(parsedFrom)) {
            initialFrom = parsedFrom;
        }
        if (isDateValid(parsedTo) && !isPast(parsedTo)) {
            initialTo = parsedTo;
        }
    }

    const [isValid, setIsValid] = useState<boolean>(true);
    const [from, setFrom] = useState(initialFrom);
    const [to, setTo] = useState(initialTo);
    const [discountId, setDiscountId] = useState(initialDiscountId || '');
    const [vehicleCategoryFilter, setVehicleCategoryFilter] = useState<
        string[]
    >([]);
    const [gearshiftFilter, setGearshiftFilter] = useState<string[]>([]);
    const [fuelTypeFilter, setFuelTypeFilter] = useState<string[]>([]);

    useEffect(() => {
        const href = generateHref({
            from,
            to,
            activeBaseCategory,
            discountId,
            allowModeChange,
            allowDiscountChange,
        });
        router.replace(href, undefined, {shallow: true});
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // never put router here or next.js will start rerendering
        // rework using zustand or something
    }, [from, to, discountId]);

    const handleFromChange = (value: Date) => {
        setFrom(value);
    };

    const handleToChange = (value: Date) => {
        setTo(value);
    };

    const handleDiscountChange = (e) => {
        setDiscountId(e.target.value);
    };

    const filteredVehicleTypes = vehicleTypes
        ?.filter((x) => {
            if (!vehicleCategoryFilter || vehicleCategoryFilter.length === 0) {
                return true;
            }
            return x.vehicle_type_categories.some((c) =>
                vehicleCategoryFilter.includes(c.vehicle_category.id)
            );
        })
        .filter((x) => {
            if (!gearshiftFilter || gearshiftFilter.length === 0) {
                return true;
            }

            return gearshiftFilter.includes(x.gearshift);
        })
        .filter((x) => {
            if (!fuelTypeFilter || fuelTypeFilter.length === 0) {
                return true;
            }

            return x.vehicles.some((v) => fuelTypeFilter.includes(v.fuel));
        });

    return (
        <Layout>
            <Stack spacing={4}>
                {allowModeChange && (
                    <ButtonGroup size="xl">
                        <Button
                            as={Link}
                            colorScheme={
                                activeBaseCategory === Base_Category_Enum.Car
                                    ? 'yellow'
                                    : 'brand'
                            }
                            href={generateHref({
                                from,
                                to,
                                activeBaseCategory: Base_Category_Enum.Car,
                                discountId,
                                allowModeChange,
                                allowDiscountChange,
                            })}
                            leftIcon={<FaCar />}
                        >
                            PKW
                        </Button>

                        <Button
                            as={Link}
                            colorScheme={
                                activeBaseCategory ===
                                Base_Category_Enum.Transporter
                                    ? 'yellow'
                                    : 'brand'
                            }
                            href={generateHref({
                                from,
                                to,
                                activeBaseCategory:
                                    Base_Category_Enum.Transporter,
                                discountId,
                                allowModeChange,
                                allowDiscountChange,
                            })}
                            leftIcon={<FaTruck />}
                        >
                            Transporter
                        </Button>
                    </ButtonGroup>
                )}
                <FromToBanner
                    initialFrom={initialFrom}
                    initialTo={initialTo}
                    onFromChange={handleFromChange}
                    onToChange={handleToChange}
                    onValidationChanged={setIsValid}
                    activeBaseCategory={activeBaseCategory}
                >
                    <WrapItem>
                        <Box>
                            <Text>Rabatt</Text>
                            {allowDiscountChange && (
                                <Select
                                    placeholder="Kein Rabatt"
                                    bg="white"
                                    value={discountId || ''}
                                    onChange={handleDiscountChange}
                                >
                                    {discounts.map((d) => (
                                        <option
                                            key={d.id}
                                            value={d.id}
                                        >{`${d.name} (${d.value} %)`}</option>
                                    ))}
                                </Select>
                            )}
                            {!allowDiscountChange && discounts.length > 0 && (
                                <Text>{`${discounts[0].name} (${discounts[0].value} %)`}</Text>
                            )}
                        </Box>
                    </WrapItem>
                </FromToBanner>

                <SectionDivider
                    label={`${filteredVehicleTypes?.length} Fahrzeug(e)`}
                />

                <Wrap spacing="3">
                    <WrapItem>
                        <CheckboxFilterPopover
                            label={
                                vehicleCategoryFilter?.length > 0
                                    ? `Kategorie (${vehicleCategoryFilter.length})`
                                    : 'Kategorie'
                            }
                            options={vehicleCategories.map((c) => ({
                                label: c.name,
                                value: c.id,
                            }))}
                            onSubmit={setVehicleCategoryFilter}
                        />
                    </WrapItem>
                    <WrapItem>
                        <CheckboxFilterPopover
                            label={
                                gearshiftFilter?.length > 0
                                    ? `Getriebe (${gearshiftFilter.length})`
                                    : 'Getriebe'
                            }
                            options={gearshiftTypes.map((g) => ({
                                label: gearshiftToText(g),
                                value: g,
                            }))}
                            onSubmit={setGearshiftFilter}
                        />
                    </WrapItem>
                    <WrapItem>
                        <CheckboxFilterPopover
                            label={
                                fuelTypeFilter?.length > 0
                                    ? `Antrieb (${fuelTypeFilter.length})`
                                    : 'Antrieb'
                            }
                            options={fuelTypes.map((f) => ({
                                label: fuelToText(f),
                                value: f,
                            }))}
                            onSubmit={setFuelTypeFilter}
                        />
                    </WrapItem>
                </Wrap>

                <VehicleTypeGrid>
                    {filteredVehicleTypes?.map((vt) => (
                        <CalculatingVehicleTypeCard
                            key={vt.id}
                            vehicleTypeId={vt.id}
                            name={vt.name}
                            imageUrl={nhost.storage.getPublicUrl({
                                fileId: vt.image_file_id,
                            })}
                            seats={vt.seats}
                            cargo_height={vt.cargo_height}
                            cargo_length={vt.cargo_length}
                            cargo_max={vt.cargo_max}
                            cargo_width={vt.cargo_width}
                            luggageNormal={vt.luggage_normal}
                            luggageMinor={vt.luggage_minor}
                            gearshift={vt.gearshift}
                            doors={vt.doors}
                            hasAirConditioner={vt.has_air_conditioner}
                            categories={vt.vehicle_type_categories.map(
                                (x) => x.vehicle_category
                            )}
                            selectedInsuranceId={initialInsuranceId}
                            selectedDiscountId={discountId}
                            selectedFrom={from}
                            selectedTo={to}
                            selectedBaseCategory={activeBaseCategory}
                            isDisabled={!isValid}
                            href={generateHref({
                                base: '/extras',
                                from,
                                to,
                                activeBaseCategory,
                                discountId,
                                insuranceId: initialInsuranceId,
                                vehicleTypeId: vt.id,
                                allowModeChange,
                                allowDiscountChange,
                            })}
                        />
                    ))}
                </VehicleTypeGrid>
            </Stack>
        </Layout>
    );
};

export const getServerSideProps: GetServerSideProps = async ({query}) => {
    const {
        allowModeChange,
        mode,
        discountType,
        discountId = null,
        insuranceId = null,
    } = query;

    const activeBaseCategory =
        mode === Base_Category_Enum.Transporter
            ? Base_Category_Enum.Transporter
            : Base_Category_Enum.Car;

    const apolloClient = createApolloClient();
    const {data} = await apolloClient.query<
        GetProductsForBaseCategoryQuery,
        GetProductsForBaseCategoryQueryVariables
    >({
        query: GetProductsForBaseCategoryDocument,
        variables: {
            base_category: activeBaseCategory,
            check_date: startOfDay(new Date()).toISOString(),
        },
    });

    const {data: vehicleTypeData} = await apolloClient.query<
        VehicleTypesQuery,
        VehicleTypesQueryVariables
    >({
        query: VehicleTypesDocument,
        variables: {
            base_category: activeBaseCategory,
        },
    });

    const vehicleTypes = vehicleTypeData.vehicle_type;

    const {data: filterData} = await apolloClient.query<
        GetFilterDataQuery,
        GetFilterDataQueryVariables
    >({
        query: GetFilterDataDocument,
        variables: {
            base_category: activeBaseCategory,
        },
    });

    const discounts = data.discounts;

    const initialDiscountId = discountId
        ? discounts.find((x) => x.id === discountId)?.id
        : null;

    const insurances = data.insurances;

    let initialInsuranceId = insuranceId
        ? insurances.find((x) => x.id === insuranceId)?.id
        : null;

    if (!initialInsuranceId) {
        initialInsuranceId =
            insurances.find((x) => x.is_default)?.id || insurances[0]?.id;
    }

    const vehicleCategories = filterData.vehicle_category.map((c) => ({
        id: c.id,
        name: c.name,
    }));

    const gearshiftTypes = filterData.gearshift.map((g) => g.value);
    const fuelTypes = filterData.vehicle.map((g) => g.fuel);

    return {
        props: {
            allowModeChange: allowModeChange || true,
            allowDiscountChange: discountType !== DiscountType.Fixed,
            initialDiscountId,
            initialInsuranceId,
            activeBaseCategory,
            discounts:
                discountType === DiscountType.Fixed
                    ? discounts.filter((d) => d.id === discountId)
                    : discounts.filter((d) => d.is_active && d.is_selectable),
            insurances,
            vehicleCategories,
            gearshiftTypes,
            fuelTypes,
            vehicleTypes,
        },
    };
};

export default IndexPage;
