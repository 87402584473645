/* eslint-disable camelcase */
import {Base_Category_Enum} from '../generated/graphql.types';

export const generateHref = ({
    base = '/',
    from = undefined,
    to = undefined,
    activeBaseCategory = undefined,
    allowDiscountChange = undefined,
    expectedKilometer = 0,
    discountId = undefined,
    allowModeChange = true,
    insuranceId = undefined,
    vehicleTypeId = undefined,
    isRideToAForeignCountry = undefined,
    foreignCountryId = undefined,
    accessories = [],
}: {
    base?: string;
    from: Date;
    to: Date;
    activeBaseCategory?: Base_Category_Enum;
    allowDiscountChange?: boolean;
    expectedKilometer?: number;
    discountId?: string;
    insuranceId?: string;
    vehicleTypeId?: string;
    allowModeChange?: boolean;
    isRideToAForeignCountry?: boolean;
    foreignCountryId?: string;
    accessories?: {id: string; amount?: number}[];
}) => {
    const searchParams = new URLSearchParams();

    if (activeBaseCategory) {
        searchParams.append('mode', activeBaseCategory);
    }

    if (discountId) {
        searchParams.append('discountId', discountId);
    }

    if (insuranceId) {
        searchParams.append('insuranceId', insuranceId);
    }

    if (vehicleTypeId) {
        searchParams.append('vehicleTypeId', vehicleTypeId);
    }

    if (from) {
        searchParams.append('from', from.toISOString());
    }

    if (to) {
        searchParams.append('to', to.toISOString());
    }

    if (!allowModeChange) {
        searchParams.append('allowModeChange', 'false');
    }

    if (!allowDiscountChange) {
        searchParams.append('discountType', 'fixed');
    }

    if (isRideToAForeignCountry) {
        searchParams.append('isRideToAForeignCountry', 'true');
    }
    if (foreignCountryId) {
        searchParams.append('foreignCountryId', foreignCountryId);
    }

    if (expectedKilometer) {
        searchParams.append('expectedKilometer', expectedKilometer.toString());
    }
    if (accessories) {
        accessories.forEach((a) =>
            searchParams.append('accessories', `${a.id}_${a.amount || 1}`)
        );
    }

    return `${base}?${searchParams.toString()}`;
};
