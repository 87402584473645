/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
import React from 'react';
import {useCalculatePrice} from './use-calculate-price';
import {VehicleTypeCard, VehicleTypeCardProps} from './vehicle-type-card';

export type CalculatingVehicleTypeCardProps = {
    vehicleTypeId: string;
    selectedInsuranceId: string;
    selectedDiscountId?: string;
} & VehicleTypeCardProps;

export const CalculatingVehicleTypeCard = ({
    vehicleTypeId,
    selectedInsuranceId,
    selectedDiscountId,
    selectedFrom,
    selectedTo,
    ...props
}: CalculatingVehicleTypeCardProps) => {
    const {priceInformation, loading} = useCalculatePrice({
        vehicle_type_id: vehicleTypeId,
        from: selectedFrom,
        to: selectedTo,
        insurance_id: selectedInsuranceId,
        expected_kilometer: 0,
        discount_id: selectedDiscountId,
    });

    return (
        <VehicleTypeCard
            priceInformation={priceInformation}
            selectedFrom={selectedFrom}
            selectedTo={selectedTo}
            hideNavigationButton={!priceInformation}
            {...props}
        />
    );
};
