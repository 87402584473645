import React from 'react';
import {HStack, Divider, Text} from '@chakra-ui/react';

export type SectionDividerProps = {
    label: string;
};

const SectionDivider = ({label}: SectionDividerProps) => (
    <HStack>
        <Divider />
        <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap">
            {label}
        </Text>
        <Divider />
    </HStack>
);

export default SectionDivider;
