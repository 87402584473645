/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
import {Button, HStack} from '@chakra-ui/react';
import React from 'react';

export type FilterActionButtonsProps = {
    onClickCancel?: VoidFunction;
    isCancelDisabled?: boolean;
    onClickApply?: VoidFunction;
};

export const FilterActionButtons = ({
    onClickApply,
    onClickCancel,
    isCancelDisabled,
}: FilterActionButtonsProps) => {
    return (
        <HStack spacing="2" justify="space-between">
            <Button
                size="sm"
                variant="ghost"
                onClick={onClickCancel}
                isDisabled={isCancelDisabled}
            >
                Abbrechen
            </Button>
            <Button size="sm" colorScheme="brand" onClick={onClickApply}>
                Übernehmen
            </Button>
        </HStack>
    );
};
