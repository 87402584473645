import React from 'react';
import {SimpleGrid, SimpleGridProps} from '@chakra-ui/react';

export const VehicleTypeGrid = ({children, ...props}: SimpleGridProps) => {
    return (
        <SimpleGrid
            columns={{base: 1, md: 2, xl: 3}}
            columnGap={{base: '6', md: '8'}}
            rowGap={{base: '10', md: '12'}}
            {...props}
        >
            {children}
        </SimpleGrid>
    );
};
